@import './variables.scss';

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body, input, button, textarea, select {
  font: 1.6rem 'Nunito', sans-serif;
  color: $color-text;
  background-color: $color-background;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration-line: none;
  color: inherit;
}

.card {
  border-radius: 15px;
  background: $color-white;
  box-shadow: (0px 4px 10px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  transition: 300ms;

  &:hover {
    background-color: $color-gray;
  }
}

.tooltip {
  position: relative;

  &::before {
    content: attr(tooltip-text);
    position: absolute;
    top: -50%;
    left: 50%;
    padding: 5px;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: $shadow;
    font-size: 12px;
    opacity: 0;
    transform: translate(-50%, -80%);
    transition: all .4s ease;
  }

  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
}

.Toastify {
  &__toast {
    &--warning {
      border: 2px solid $color-secondary;
      color: $color-text;
    }
    &--error {
      background: $color-error;
      color: $color-white;
    }
  }
}
