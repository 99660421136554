@import '../../assets/styles/variables.scss';

.Modal-signature-ways-play-pending {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;

  &__title {
    text-align: center;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    max-width: 400px;
  }

  &__fields {
    flex-direction: column;
  }

  &__fields__line1 {
    flex-direction: unset;
    margin-bottom: 16px;
  }

  &__fields, &__fields__line1 {
    width: 100%;
    display: flex;
    gap: 16px;

    &__cpf {
      max-width: 165px;
    }

    &__phone__wrapper {
      max-width: 400px;
    }

    &__phone__wrapper__fields {
      display: flex;
    }

    &__phonecc {
      max-width: 90px;
    }

    &__phone {
      max-width: 250px;
    }

    &__cep {
      max-width: 200px;
      display: flex;
      align-items: center;
      gap: 8px;

      & > button {
        display: grid;
        place-items: center;
        background-color: transparent;
        cursor: pointer;
        color: #1414cc;
      }
    }

    &__street {
      max-width: 350px;
    }

    &__number {
      max-width: 130px;
    }

    &__complement, &__district, &__city, &__state {
      max-width: 200px;
    }

    &__complement {
      max-width: 100px;
    }

    &__state__dropdown__wrapper {
      max-width: 100px;
    }
  }

  &__address-fields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 30px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 16px;
    justify-content: center;

    & a{
      padding: 8px 24px;
      border-radius: 50px;
      border: 2px solid transparent;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      font-size: 1em;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: all 200ms;

      background-color: $color-secondary;
    }
  }

  @media (max-width: 460px) {
    &__buttons {
      flex-direction: column-reverse;
      gap: 8px;
    }
  }
}
