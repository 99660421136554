@import '@src/assets/styles/variables.scss';

.Tutorial-output-overlay {
  width: max-content;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto 24px;
  padding: 24px;
  border-radius: 15px;
  border: 1px solid $color-border;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  box-shadow: $shadow;
  background-color: $color-white;
  text-align: center;
  
  .Button {
    width: fit-content;
  }

  @media (max-width: 540px) {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 0;
    border-radius: 15px 15px 0 0;
  }
}
