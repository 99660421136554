@import '../../assets/styles/variables.scss';

@keyframes zoomEffect {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.Modal {
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  &__container {
    width: 100%;
    max-height: 90vh;
    margin: 10px;
    border: 10px solid transparent;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    position: relative;
    animation: zoomEffect 1s ease;
  }

  &__close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $color-text;
    transition: 300ms;

    & > svg {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
}
