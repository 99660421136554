@import '../../assets/styles/variables.scss';

.UserMenu {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  &__subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    background: transparent;
    cursor: pointer;


    & small {
      font-size: 1.1rem;
    }

    &__tag {

      &__trial
      ,&__suspended
      ,&__pending
      ,&__active
      {
        padding: 5px;
        padding: 1px 5px;
        border-radius: 5px;
        color: $color-white;
      }

      &__trial {
        background-color: #57cf5a;
      }

      &__active {
        background-color: #c8d6e5;
      }

      &__suspended {
        background-color: #c00;
      }

      &__pending {
        background-color: #ff9f43;
      }

    }

    &__expiration-days {
      color: $color-error;
      font-size: 12px;
    }
  }

  &__subscription_status_v1 {

    width: 100px;
    text-align: center;
    margin-right: 10px;

    &__plan {
      font-size: 1.4rem;
      margin-bottom: 2px;

      background: #4cd137;
      color: #FFF;
      padding: 4px;
      border-radius: 4px;
    }

    &__expiration {
      font-size: 1rem;
      color: #C00;
    }

    & > span {
      display: block;
    }
  }

  &__button {
    width: 40px;
    height: 40px;
    padding: 2px;
    cursor: pointer;
    background: none;
    border-radius: 50%;
    background-image: conic-gradient($color-tertiary, $color-primary-dark, $color-primary, $color-secondary, $color-tertiary);
  }

  &__menu-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background-color: transparent;

    & > span {
      width: 20px;

      background-color: $color-text;
      border: 1px solid $color-text;
      border-radius: 10px;

      transition: transform 0.3s ease-out, opacity 0.3s ease-out;

      &:nth-child(1) {
        transform: none;
      }
      &:nth-child(2) {
        opacity: 1;
      }
      &:nth-child(3) {
        transform: none;
      }
    }

    &--open {
      span {
        &:nth-child(1) {
          transform: rotate(-45deg) translate(-4px, 4px);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(45deg) translate(-4px, -4px);
        }
      }
    }
  }

  &__dropMenu {
    background-color: $color-white;
    border: 2px solid $color-border;
    border-radius: 15px;
    position: absolute;
    top: 60px;
    right: -10px;
    transform: scale(0);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    white-space: nowrap;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 2px solid $color-border;
      border-left: 2px solid $color-border;
      position: absolute;
      top: -1.5px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: inherit;
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 0;
      margin: 0 16px;
      border-bottom: 1px solid $color-border;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }

  &__page {
    width: 100%;
    font-size: 14px;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
