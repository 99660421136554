@import '../../assets/styles/variables.scss';

.Input {
  width: 100%;
  position: relative;

  & > input {
    height: 40px;
    width: 100%;
    border-radius: 50px;
    border: 2px solid $color-border;
    background-color: $color-white;
    padding: 0 16px;
    font-size: 1em;
    color: inherit;
    transition: 500ms;

    &[type="date"] {
      cursor: text;
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
    }

    &:focus {
      border-color: $color-primary-dark;

      & ~ .Input__icon {
        color: $color-primary-dark;
      }
    }

    &:not(:read-only):not(:disabled):hover {
      background-color: $color-background;
    }

    &:read-only,
    &:read-only:focus,
    &:read-only + button,
    &:read-only:focus + button
    {
      cursor: default;
      filter: opacity(50%);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    display: grid;
    place-items: center;
    background-color: transparent;
    pointer-events: none;
    color: $color-text-light;
    transition: color 500ms;

    &--toggle-password-button {
      left: initial;
      right: 16px;
      pointer-events: auto;
      border-radius: 50%;
      color: $color-text;

      &:focus {
        color: $color-primary-dark;
        outline: solid $color-primary-dark;
        outline-offset: 2px;
      }
    }
  }

  &--password > input {
    padding-right: calc(16px + 16px + 8px);
  }

  &--has-icon > input {
    padding-left: calc(16px + 16px + 8px);
  }

  &--error > input {
    border-color: $color-error;
    color: $color-error;
  }
}
