@import '../../assets/styles/variables.scss';

.Form-signature-ways-play {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;

  .Input > input
  ,.Input-group select
  {
    border-radius: 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 24px;
    text-align: center;
    max-width: 400px;
  }

  &__fields {
    flex-direction: column;
  }

  &__fields__line1 {
    flex-direction: unset;
    margin-bottom: 16px;
  }

  &__fields, &__fields__line1 {
    width: 100%;
    display: flex;
    gap: 16px;

    &__cpf {
      max-width: 165px;
    }

    &__phone__wrapper {
      max-width: 400px;
    }

    &__phone__wrapper__fields {
      display: flex;
    }

    &__phonecc.Input {
      max-width: 60px;
      margin-right: 1px;

      & > input {
        padding: 0 10px;
      }
    }

    &__phoneccprefix.Input{
        max-width: 30px;

        & > input:focus {
          border-color: #CCC;
          border-width: unset;
          opacity: unset;
        }

        & > input {
          padding: 0;
          text-align: center;
          background: #EEE;
          border-right: 0;
          color: #000;
        }
    }

    &__phone {
      max-width: 250px;
    }

    &__cep {
      max-width: 200px;
      display: flex;
      align-items: center;
      gap: 8px;

      & > button {
        display: grid;
        place-items: center;
        background-color: transparent;
        cursor: pointer;
        color: #1414cc;
      }
    }

    &__street {
      max-width: 350px;
    }

    &__number {
      max-width: 130px;
    }

    &__complement, &__district, &__city, &__state {
      max-width: 200px;
    }

    &__complement {
      max-width: 100px;
    }

    &__state__dropdown__wrapper {
      max-width: 100px;
    }
  }

  &__address-fields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 30px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 16px;
  }

  @media (max-width: 460px) {
    &__buttons {
      flex-direction: column-reverse;
      gap: 8px;
    }
  }
}
