@import '../../assets/styles/variables.scss';

.Header {
  width: 100%;
  padding: 25px 0;
  background-color: $color-white;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  z-index: 10;

  &__container {
    width: 100%;
    max-width: $max-container;
    padding: 0 $padding-container;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  &__logos {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    gap: 16px;
  }

  &__logo {
    width: 100%;
    height: auto;
    max-height: 28px;
  }

  &__plus {
    width: 100%;
    max-width: 20px;
    height: auto;
  }

  &__logo-ways {
    display: grid;
    place-items: center;
  }

  &__nav {
    & > ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      li {
        list-style: none;
        display: flex;
      }
    }
  }

  &__page {
    font-weight: 300;
    padding: 5px 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: $color-primary;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    &--active {
      color: $color-primary;
      font-weight: bold;
    }
  }

  &__menu-icon {
    display: none;
    background-color: transparent;
    cursor: pointer;

    & > span {
      width: 30px;

      background-color: $color-primary-dark;
      border: 2px solid $color-primary-dark;
      border-radius: 10px;

      transition: transform 0.5s ease-out, opacity 0.5s ease-out;

      &:nth-child(1) {
        transform: none;
      }
      &:nth-child(2) {
        opacity: 1;
      }
      &:nth-child(3) {
        transform: none;
      }
    }

    &--open {
      span {
        &:nth-child(1) {
          transform: rotate(-45deg) translate(-6px, 6px);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(45deg) translate(-6px, -6px);
        }
      }
    }
  }

  &__staff-band {
    position: absolute;
    background: #FFE800;
    width: 100%;
    top: 0;
    color: red;
    min-height: 18px;
    font-size: 14px;
    padding: 4px 20px;
    line-height: 1.1em;
    font-family: sans-serif;
    color: #050533;
    display: flex;
    flex-direction: row;
    place-content: space-between;
  }

  @media (max-width: 860px) {
    &__nav {
      background: #fff;
      padding-top: 87px;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      transform: translateX(150%);
      transition: transform 0.5s ease-out;

      & > ul {
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
      }

      &--open {
        transform: translateX(0);
      }
    }

    &__page {
      margin: 0 10px 0 30px;
    }

    &__menu-icon {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
