@import '../../assets/styles/variables.scss';

.footer {
  width: 100%;

  padding: 20px;

  background-color: $color-white;
  border-top: 1px solid #e5e5e5;

  &__container {
    width: 100%;
    max-width: $max-container;

    padding: 0 $padding-container;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__social-ways {
    width: 325px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 28px;
    height: 28px;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-secondary;
    border-radius: 50%;
    box-shadow: $shadow;
    cursor: pointer;
    transition: 500ms;

    &:hover {
      transform: translateY(-5px);
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      height: 15px;
      width: 15px;
    }
  }

  &__copyright {
    width: 325px;
    color: $color-text-light;
    text-align: center;
  }

  &__pages {
    width: 325px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      list-style: none;
      font-weight: bold;

      a:hover {
        text-decoration: underline;
      }
    }
  }

  &__tutorial-button {
    display: grid;
    place-items: center;
    cursor: pointer;
  }

  @media (max-width: $max-container) {
    &__container {
      flex-direction: column;
    }

    &__socialWays {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__copyright {
      margin: 10px 0;
    }
  }
}
