@import '../../assets/styles/variables.scss';

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.LoaderSpinner {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background-color: #ffffff95;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;

  &__spinner {
    width: 100px;
    height: 100px;

    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: $color-primary-dark;

    animation: spin 0.5s linear infinite;
  }

  &__spinner::after {
    content: '';

    border-radius: 50%;
    border: 5px solid transparent;
    border-bottom-color: $color-tertiary;

    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;

    animation: spin 1s linear infinite;
  }

  &__spinner::before {
    content: '';

    border-radius: 50%;
    border: 5px solid transparent;
    border-right-color: $color-secondary;

    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;

    animation: spin 2s linear infinite;
  }
}
