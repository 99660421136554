@import '../../assets/styles/variables.scss';

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
}

.Default-profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: $color-background;
  display: grid;
  place-items: center;

  & > svg {
    width: 100%;
    height: 100%;
  }

  & > img {
    width: 100%;
    height: auto;
  }

  &__loading {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;

    &::before {
      content: '';
      width: 30%;
      height: 30%;
      border: 2px solid transparent;
      border-top-color: $color-text-light;
      position: absolute;
      border-radius: 50%;
      animation: rotate .5s infinite linear;
    }
  }
}
