@import '../../assets/styles/variables.scss';

.Button {
  width: 100%;
  padding: 8px 24px;
  border-radius: 50px;
  border: 2px solid transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 200ms;

  &:disabled {
    filter: opacity(60%);
    cursor: not-allowed;
    box-shadow: none;
  }

  &--primary {
    background-color: $color-secondary;

    &:not(:disabled):hover {
      box-shadow: inset 0px 0px 30px $color-white;
    }
  }

  &--secondary {
    background-color: $color-white;
    border-color: $color-secondary;

    &:not(:disabled):hover {
      box-shadow: inset 0px 0px 30px $color-secondary;
    }
  }

  &--tertiary {
    background-color: $color-tertiary;
    color: $color-white;

    &:not(:disabled):hover {
      box-shadow: inset 0px 0px 30px $color-white;
    }
  }
}
