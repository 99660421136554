@import '@src/assets/styles/variables.scss';

.Input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    font-size: 18px;
  }

  &__helper-text {
    margin-left: 16px;
    color: $color-error;
    font-size: 13px;
  }

  & select {
    border: 2px solid #ededed;
    background-color: $color-white;
    border-radius: 100px;
    cursor: pointer;
    padding: 0 16px;
    font-size: 1em;
    height: 40px;

    &:focus-within {
      border-color: $color-primary-dark;
    }
  }
}
