$color-primary: #C5168C;
$color-primary-dark: #800280;
$color-secondary: #F3C249;
$color-secondary-light: #dca313;
$color-tertiary: #01A6A3;
$color-text: #2C2738;
$color-text-light: #888888;
$color-gray: #EBE2ED;
$color-blue: #2D1063;
$color-white: #FFFFFF;
$color-error: #E91E25;
$color-selected: #F8E0A4;
$color-border: #E0E0E0;
$color-background: #F5F5F5;
$color-success: #07bc0c;

$shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

$max-container: 1210px;
$padding-container: 10px;
